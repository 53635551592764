const Main = () => import('@/views/main/index.vue').then(m => m.default || m)
const Notice = () => import('@/views/notice/index.vue').then(m => m.default || m)
const NoticeDetail = () => import('@/views/notice/detail.vue').then(m => m.default || m)
const Faq = () => import('@/views/faq/index.vue').then(m => m.default || m)
const Qna = () => import('@/views/qna/index.vue').then(m => m.default || m)
const QnaDetail = () => import('@/views/qna/detail.vue').then(m => m.default || m)
const QnaReg = () => import('@/views/qna/reg.vue').then(m => m.default || m)
const Login = () => import('@/views/sign/login.vue').then(m => m.default || m)
const Agreement = () => import('@/views/sign/agreement.vue').then(m => m.default || m)
const Complete = () => import('@/views/sign/complete.vue').then(m => m.default || m)
const Mypage = () => import('@/views/mypage/index.vue').then(m => m.default || m)
const MypageScore = () => import('@/views/mypage/score.vue').then(m => m.default || m)
const MypageEdit = () => import('@/views/mypage/edit.vue').then(m => m.default || m)
const MypageStu = () => import('@/views/mypage/class/students.vue').then(m => m.default || m)
const MyClass = () => import('@/views/mypage/class/index.vue').then(m => m.default || m)
const MyQuiz = () => import('@/views/mypage/class/quiz.vue').then(m => m.default || m)
const Manual = () => import('@/views/manual/index.vue').then(m => m.default || m)
const Manual1 = () => import('@/views/manual/index1.vue').then(m => m.default || m)
const Terms = () => import('@/views/terms/terms.vue').then(m => m.default || m)
const Policy = () => import('@/views/terms/policy.vue').then(m => m.default || m)
const Personal = () => import('@/views/terms/personal.vue').then(m => m.default || m)
const eventDetail = () => import('@/views/notice/eventDetail.vue').then(m => m.default || m)
const eventDetail1 = () => import('@/views/notice/eventDetail1.vue').then(m => m.default || m)
const eventDetail2 = () => import('@/views/notice/eventDetail2.vue').then(m => m.default || m)
const eventDetail3 = () => import('@/views/notice/eventDetail3.vue').then(m => m.default || m)
const checkItem = () => import('@/views/check/check.vue').then(m => m.default || m)
export default [
    {
        path: '',
        name: 'main',
        component: Main,
        props: true,
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms,
        props: true,
    },
    {
        path: '/policy',
        name: 'policy',
        component: Policy,
        props: true,
    },
    {
        path: '/personal',
        name: 'personal',
        component: Personal,
        props: true,

    },
    {
        path: '/notice',
        name: 'notice',
        component: Notice,
        props: true,

    },
    {
        path: '/notice/detail/:seq',
        name: 'notice/detail',
        component: NoticeDetail,
        props: true,
    },
    {
        path: '/notice/eventDetail',
        name: 'notice/eventDetail',
        component: eventDetail,
        props: true,
    },
    {
    path: '/notice/eventDetail1',
    name: 'notice/eventDetail1',
    component: eventDetail1,
    props: true,
	},
    {
    path: '/notice/eventDetail2',
    name: 'notice/eventDetail2',
    component: eventDetail2,
    props: true,
	},
    {
    path: '/notice/eventDetail3',
    name: 'notice/eventDetail3',
    component: eventDetail3,
    props: true,
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        props: true,

    },
    {
        path: '/qna',
        name: 'qna',
        component: Qna,
        props: true,

    },
    {
        path: '/qna/detail/:seq',
        name: 'qna/detail',
        component: QnaDetail,
        props: true,

    },
    {
        path: '/qna/reg',
        name: 'qna/reg',
        component: QnaReg,
        props: true,

    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,

    },
    {
        path: '/agree',
        name: 'agree',
        component: Agreement,
        props: true,

    },
    {
        path: '/join/complete',
        name: 'complete',
        component: Complete,
        props: true,

    },
    {
        path: '/mypage',
        component: Mypage,
        props: true,
        children: [
            {
                name: 'mypage',
                path: '',
                component: MypageEdit,
                props: true,
            },
            {
                name: 'score',
                path: 'score',
                component: MypageScore,
                props: true,
            },
            {
                name: 'students',
                path: 'students/:classId',
                component: MypageStu,
                props: true,
            },
            {
                name: 'class',
                path: 'class',
                component: MyClass,
                props: true,
            },
            {
                name: 'quiz',
                path: 'quiz/:classId',
                component: MyQuiz,
                props: true,
            },
        ],
        // redirect: '/mypage/edit',
    },
    {
        path: '/manual',
        name: 'manual',
        component: Manual,
        props: true,
    },
    {
        path: '/manual1',
        name: 'manual1',
        component: Manual1,
        props: true,
    },
      {
        path: '/check',
        name: 'check',
        component: checkItem,
        props: true,
    },
    {
        path: '*',
        redirect: '/',
    },
]